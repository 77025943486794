* {
  outline: none;
}

button {
  -webkit-appearance: none;
}
* {
  box-sizing: border-box;
}
div {
  display: block;
}

button:hover {
  opacity: 0.8;
}
.App {
  display: none;
  height: 100vh;
}
.App.show {
  display: flex;
}

.landing-screen {
  color: white;
}

.flash-section, .landing-screen {
  background: url(./assets/img/background.png) no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 100%;
}

.loading-screen {
  background: url(./assets/img/background.png) no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  width: 100%;
}

.flash-section .col-full {
  padding-right: 0;
  padding-left: 0;
}

.App-link {
  color: #191a19;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.forms {
  display: flex;
  flex-wrap: wrap;
}

.form-group {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 1rem;
  height: 100%;
}

.form-control {
  width: 100%;
  background-color: #fff;
  padding: 7px 10px;
  margin: 0;
  height: auto;
  border: 1px solid #f1f1f1;
  display: block;
  margin-bottom: 5px;
}

.col-full {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 1rem;
}

.btn {
  padding: 7px 20px;
  font-size: 16px;
  width: 162px;
  height: 61px;
  display: block;
}
.text-center {
  text-align: center;
}

.loading__message:after {
  -webkit-animation: spin infinite 1s;
  animation: spin infinite 1s;
  background-image: url(./assets/img/logo.white.svg);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 40px 40px;
  content: '';
  display: inline-block;
  height: 40px;
  margin: 0 0 0 20px;
  width: 40px;
}

.link-forgot-password {
  color: #808080 !important;
  font-weight: 500;
  margin-top: 8px !important;
  text-decoration: none;
  letter-spacing: 0.1;
  font-size: 12px !important;
}

.p-relative {
  position: relative;
}

.container {
  display: block;
  padding: 0 15px;
}

.login-page-label {
  color: #808080 !important;
  font-weight: 500 !important;
  line-height: 24px;
  margin-bottom: 4px;
  margin-top: 24px;
  font-size: 12px !important;
}


.login-page-input {
  flex: 0 0 100%;
  margin-bottom: 4px;
}

.login-page h2 {
  color: #000;
  font: 400 22px/38px;
  letter-spacing: 0.22px;
}

.snackbar {
  background-color: #000;
  border-width: 1px;
  color: #fff;
  border: 1px solid #000;
}

.snackbar-top {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 0;
  font-size: 16px;
  padding: 7px 0;
}

.btn.btn--standard:disabled,
.btn.btn--standard[disabled] {
  background-color: #fff;
  border: 1px solid rgb(105, 105, 105);
}

.btn.btn--standard:hover:disabled,
.btn.btn--standard:hover[disabled] {
  background-color: #fff;
  border: 1px solid rgb(105, 105, 105);
}

label.form__checkbox {
  display: block;
}

.skinned-wrapper {
  border: 3px solid #E5E5E5;
  color: #9D9D9D;
  border-radius: 8px;
  height: 43px;
  letter-spacing: 0.14px;
  font-size: 14px;
  line-height: 37px;
  letter-spacing: 0.14px;
  padding-left: 18px;
  outline: none;
  -webkit-transition: border-color 200ms ease;
  transition: border-color 200ms ease;
  text-align: left;
  width: 100%;
}

.skinned-wrapper-white {
  background: #fff;
}

.skinned-wrapper.error {
  border-bottom-color: red;
}
.skinned-wrapper.error .skinned-text {
  color: red;
}

@media screen and (min-width: 768px) {
  .skinned-wrapper {
    font-size: 18px;
    letter-spacing: 0.18px;
    /* line-height: 72px; */
  }
}

.skinned-wrapper:before {
  border-top: 5px solid #cecece;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  content: '';
  display: inline;
  width: 0;
  position: absolute;
  right: 16px;
  margin-top: 16px;
}

.skinned-wrapper select {
  opacity: 0;
  filter: alpha(opacity=0);
  width: 100%;
  height: 100%;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  height: 16.2px;
  font-size: 18px;
  line-height: 42px;
  padding: 25.7px 0;
  border-bottom: 1px solid #cecece;
}

.skinned-wrapper .skinned-text {
  color: #9D9D9D;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.14px;
  padding: 2px 0 0;
  position: absolute;
  width: 100%;
  z-index: 0;
}


.skinned-wrapper .skinned-text,
.skinned-wrapper select,
.skinned-wrapper select option {
  color: #9D9D9D;
  font-size: 18px;
  letter-spacing: 0.14px;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .skinned-wrapper .skinned-text,
  .skinned-wrapper select,
  .skinned-wrapper select option {
    font-size: 14px;
    line-height: 32px;
  }
}


/*
 */
.skinned-wrapper-sidebar {
  border: 3px solid #E5E5E5;
  color: #9D9D9D;
  border-radius: 8px;
  height: 43px;
  letter-spacing: 0.14px;
  font-size: 14px;
  line-height: 37px;
  letter-spacing: 0.14px;
  padding-left: 18px;
  outline: none;
  -webkit-transition: border-color 200ms ease;
  transition: border-color 200ms ease;
  text-align: left;
  width: 100%;
}

.skinned-wrapper-sidebar-white {
  background: #fff;
}

.skinned-wrapper-sidebar.error {
  border-bottom-color: red;
}
.skinned-wrapper-sidebar.error .skinned-text {
  color: red;
}

@media screen and (min-width: 768px) {
  .skinned-wrapper-sidebar {
    font-size: 18px;
    letter-spacing: 0.18px;
    /* line-height: 72px; */
  }
}

.skinned-wrapper-sidebar select {
  opacity: 0;
  filter: alpha(opacity=0);
  width: 100%;
  height: 100%;
}

.skinned-wrapper-sidebar .skinned-text {
  color: #9D9D9D;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.14px;
  padding: 2px 0 0;
  position: absolute;
  width: 100%;
  z-index: 0;
}


.skinned-wrapper-sidebar .skinned-text,
.skinned-wrapper-sidebar select,
.skinned-wrapper-sidebar select option {
  color: #9D9D9D;
  font-size: 18px;
  letter-spacing: 0.14px;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .skinned-wrapper-sidebar .skinned-text,
  .skinned-wrapper-sidebar select,
  .skinned-wrapper-sidebar select option {
    font-size: 14px;
    line-height: 32px;
  }
}

.skinned-select {
  background-color: rgb(37, 37, 37);
}

.error-message {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}
