.modal {
  background: white;
  height: 0;
  left: 0;
  opacity: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  transform: translateY(20px);
  transition: height 0s 100ms, opacity 100ms, transform 100ms;
  width: 100%;
  z-index: 40000;
}

.setting-container {
  background: '#000 !important';
}

.modal-open .modal {
  height: 100%;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 100ms, transform 100ms;
}
.modal__close {
  background: url(../../assets/img/icon.modal.close.svg) no-repeat;
  background-size: 100% 100%;
  height: 18px;
  position: absolute;
  right: 40px;
  top: 40px;
  width: 18px;
  padding: 0;
  outline: none;
  padding: 0;
  border: 0;
}

.modal__close__white {
  background: url(../../assets/img/x.svg) no-repeat;
  background-size: 100% 100%;
  height: 18px;
  position: absolute;
  right: 40px;
  top: 40px;
  width: 18px;
  padding: 0;
  outline: none;
  padding: 0;
  border: 0;
}

.modal__content {
  padding: 40px;
}
.modal__title {
  color: #666666;
  font: 500 28px 'Gotham';
  margin: 0;
  text-align: left;
  text-transform: none;
}
.modal__form {
  margin: 40px 0 0;
  max-width: 660px;
}
.modal__form__row {
  overflow: hidden;
  margin-bottom: 21px;
  position: relative;
}
@media screen and (min-width: 768px) {
  .modal__form__row {
    margin-bottom: 34px;
  }
}
.modal__form__row--smaller {
  font-size: 0;
}
@media screen and (min-width: 768px) {
  .modal__form__row--smaller {
    margin-bottom: 25px;
  }
}
.modal__form__row--flush {
  margin-bottom: 0;
}
.modal__form__row--flush:last-of-type {
  margin-bottom: 21px !important;
}
@media screen and (min-width: 768px) {
  .modal__form__row--flush:last-of-type {
    margin-bottom: 34px !important;
  }
}
.modal__form__row--group {
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .modal__form__row--group {
    margin-bottom: 34px;
  }
}
@media screen and (min-width: 768px) {
  .modal__form__row--group--smaller {
    margin-bottom: 25px;
  }
}
.modal__form__row--with-bottom-border {
  border-bottom: 1px solid #cecece;
}
.modal__form__row__half {
  margin-bottom: 31px;
}
@media screen and (min-width: 768px) {
  .modal__form__row__half {
    float: left;
    margin-bottom: 0;
    width: calc(50% - 12px);
  }
}
@media screen and (min-width: 768px) {
  .modal__form__row__half:first-of-type {
    margin-right: 24px;
  }
}
.modal__form__row__half:last-of-type {
  margin-bottom: 0;
}
.modal__form__row__half--secondary {
  float: left;
  width: calc(50% - 7px);
}
@media screen and (min-width: 768px) {
  .modal__form__row__half--secondary {
    width: calc(50% - 12px);
  }
}
.modal__form__row__half--secondary:first-of-type {
  margin-right: 14px;
}
@media screen and (min-width: 768px) {
  .modal__form__row__half--secondary:first-of-type {
    margin-right: 24px;
  }
}
.modal__form__row__half--secondary--full {
  margin-right: 0;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .modal__form__row__half--secondary--full {
    width: calc(50% - 12px);
  }
}
.modal__form__row__seventy {
  float: left;
  width: 70%;
}
.modal__form__row__thirty {
  float: left;
  padding-left: 24px;
  width: 30%;
}
.modal__form__row__thirty--flush {
  padding-left: 6px;
}
.modal__form__row a {
  color: rgba(15, 18, 40, 0.9);
  transition: color 300ms ease;
}
.modal__form__label {
  color: #808080;
  display: block;
  line-height: 24px;
  letter-spacing: 0.1;
  font: 500 16px 'DM Sans';
}

.modal__form__label__setting {
  color: #808080;
  display: block;
  font: 500 16px 'DM Sans';
  letter-spacing: letter-spacing(10, 14px);
}
.modal__form__label--inline {
  float: left;
  line-height: 54px;
}
.modal__form__label--smaller {
  color: #cecece;
  font: 500 14px/18px 'GTWalsheim', 'Helvetica Neue', 'Helvetica', Arial,
    sans-serif;
  letter-spacing: letter-spacing(10, 14px);
}
.modal__form__note {
  color: #999;
  display: block;
  font: 500 14px/18px 'GTWalsheim', 'Helvetica Neue', 'Helvetica', Arial,
    sans-serif;
  letter-spacing: letter-spacing(10, 14px);
  margin: 0;
}
.modal__form__text-input {
  background-color: transparent;
  border: 0 none;
  border-bottom: 1px solid #cecece;
  height: 37px;
  font: 500 16px 'DM Sans';
  letter-spacing: letter-spacing(10, 14px);
  outline: none;
  transition: border-color 200ms ease;
  text-align: left;
  width: 100%;
  -webkit-appearance: none;
  color: #9D9D9D;
}
@media screen and (min-width: 768px) {
  .modal__form__text-input {
    font-size: 18px;
    height: 42px;
    letter-spacing: letter-spacing(10, 18px);
    line-height: 42px;
  }
}
.modal__form__text-input::-webkit-input-placeholder {
  color: #969696;
  font: 500 10px/40px 'GTWalsheim', 'Helvetica Neue', 'Helvetica', Arial,
    sans-serif;
  letter-spacing: letter-spacing(10, 10px);
  text-align: left;
}
@media screen and (min-width: 768px) {
  .modal__form__text-input::-webkit-input-placeholder {
    font-size: 16px;
    letter-spacing: letter-spacing(10, 16px);
    line-height: 42px;
  }
}
.modal__form__text-input:-moz-placeholder {
  color: #969696;
  font: 500 10px/40px 'GTWalsheim', 'Helvetica Neue', 'Helvetica', Arial,
    sans-serif;
  letter-spacing: letter-spacing(10, 10px);
  text-align: left;
}
@media screen and (min-width: 768px) {
  .modal__form__text-input:-moz-placeholder {
    font-size: 16px;
    letter-spacing: letter-spacing(10, 16px);
    line-height: 42px;
  }
}
.modal__form__text-input:-ms-input-placeholder {
  color: #969696;
  font: 500 10px/40px 'GTWalsheim', 'Helvetica Neue', 'Helvetica', Arial,
    sans-serif;
  letter-spacing: letter-spacing(10, 10px);
  text-align: left;
}
@media screen and (min-width: 768px) {
  .modal__form__text-input:-ms-input-placeholder {
    font-size: 16px;
    letter-spacing: letter-spacing(10, 16px);
    line-height: 42px;
  }
}
.modal__form__text-input.placeholder {
  color: #969696;
  font: 500 10px/40px 'GTWalsheim', 'Helvetica Neue', 'Helvetica', Arial,
    sans-serif;
  letter-spacing: letter-spacing(10, 10px);
  text-align: left;
}
@media screen and (min-width: 768px) {
  .modal__form__text-input.placeholder {
    font-size: 16px;
    letter-spacing: letter-spacing(10, 16px);
    line-height: 42px;
  }
}
.modal__form__text-input:focus {
  border-color: rgba(15, 18, 40, 0.9);
}
.modal__form__text-input--qty {
  color: #787878;
  display: inline-block;
  height: 21px;
  line-height: 23px;
  padding: 4px 0 0;
  text-align: center;
  vertical-align: middle;
  width: 22px;
}
.modal__form__text-input--postcode-find {
  display: inline-block;
  width: 140px;
}
.modal .opentok-hardware-setup-preview {
  margin: 20px 0 0;
}
.modal .opentok-hardware-setup-activity-bg {
  background: #e4e4e4;
}
.modal .opentok-hardware-setup-activity-bg:after {
  border-top: 5px solid white;
  content: '';
  display: block;
  height: 5px;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#6eff5b+61,fcff70+76,faff00+87,ff7070+100 */
  background: #6eff5b;
  /* Old browsers */
  background: -moz-linear-gradient(
    left,
    rgba(110, 255, 91, 1) 61%,
    rgba(252, 255, 112, 1) 76%,
    rgba(250, 255, 0, 1) 87%,
    rgba(255, 112, 112, 1) 100%
  );
  /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(110, 255, 91, 1) 61%,
    rgba(252, 255, 112, 1) 76%,
    rgba(250, 255, 0, 1) 87%,
    rgba(255, 112, 112, 1) 100%
  );
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(110, 255, 91, 1) 61%,
    rgba(252, 255, 112, 1) 76%,
    rgba(250, 255, 0, 1) 87%,
    rgba(255, 112, 112, 1) 100%
  );
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6eff5b', endColorstr='#ff7070',GradientType=1);
  /* IE6-9 */
}
.modal .hardware-setup-activity-fg {
  background: #ecfc54;
  height: 20px;
  width: 0;
}
.modal__av-settings {
  margin-bottom: 21px;
}
@media screen and (min-width: 768px) {
  .modal__av-settings {
    margin-bottom: 34px;
  }
}
.modal__av-settings .modal__form__row.last {
  margin: 0 0;
}

.modal .hardware-setup-preview {
  margin: 20px 0 0;
  position: relative;
}

.modal .hardware-setup-preview video {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.video-container {
  height: 300px;
  width: 300px;
  position: relative;
}
.video-loading {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  background-color: #000;
}

.modal .hardware-setup-activity-bg {
  background-color: #e4e4e4;
}

.modal .hardware-setup-activity-gg {
  background-color: #ecfc54;
  height: 20px;
}

.modal .hardware-setup-activity-bg:after {
  border-top: 5px solid white;
  content: '';
  display: block;
  height: 5px;
  background: #6eff5b;
  background: -webkit-linear-gradient(
    left,
    #6eff5b 61%,
    #fcff70 76%,
    #faff00 87%,
    #ff7070 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(61%, #6eff5b),
    color-stop(76%, #fcff70),
    color-stop(87%, #faff00),
    to(#ff7070)
  );
  background: linear-gradient(
    to right,
    #6eff5b 61%,
    #fcff70 76%,
    #faff00 87%,
    #ff7070 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6eff5b', endColorstr='#ff7070',GradientType=1 );
}

@media screen and (min-width: 768px) {
  .modal__form__row {
    margin-bottom: 34px;
  }
}

.modal__form__row {
  overflow: hidden;
  margin-bottom: 21px;
  position: relative;
}
