@import url('https://fonts.googleapis.com/css?family=DM+Sans:regular,bold');
body {
  margin: 0;
  padding: 0;
  font-family: 'DM Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'DM Sans';
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: poin;
}
a {
  color: #fff;
}

a:visited {
  color: #fff;
}
a:hover {
  color: #fff;
  text-decoration: none;
}

p {
  display: block;
  margin: 0 0 1rem;
}

.row {
  display: flex;
  margin: 0 -15px;
  flex-wrap: wrap;
}

.col {
  box-sizing: border-box;
  padding: 0 15px;
}

.col-half {
  max-width: 50%;
  flex: 0 0 50%;
}

.text-right {
  text-align: right;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.8rem !important;
}

.management-page {
  background: #ffffff;
  height: 100%;
  position: relative;
  padding: 0;
  width: -webkit-calc(100% - 60px);
  width: calc(100% - 60px);
}

.management-panel {
  background: #ffffff;
  height: 100%;
  overflow-y: auto;
  padding: 53px 30px 0;
  /* position: absolute; */
  right: 0;
  -webkit-transition: width 300ms ease, right 300ms ease;
  transition: width 300ms ease, right 300ms ease;
}

.management-panel__block {
  display: block;
}

.form__row {
  flex: 0 0 100%;
  *zoom: 1;
  margin-bottom: 21px;
  position: relative;
}

.form__row--flush {
  margin-bottom: 0 !important;
}

.form__row a {
  color: rgba(15, 18, 40, 0.9);
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
}

.add-another {
  color: rgba(15, 18, 40, 0.9);
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 0.14px;
  line-height: 54px;
  text-decoration: underline;
  border: none;
}

.add-another:hover {
  text-decoration: none;
}

.add-another::before {
  border: 2px solid #ffffff;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  color: #ffffff;
  content: '+';
  display: inline-block;
  font: 400 26px/20px 'DM Sans';
  height: 23px;
  margin-right: 8px;
  text-align: center;
  vertical-align: middle;
  width: 23px;
}

.form__row__remove {
  cursor: pointer;
  position: absolute;
  top: 64%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 0;
  overflow: hidden;
  right: 0;
  text-decoration: none;
}

.form__row__remove::after {
  border: 2px solid #959595;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  color: #959595 !important;
  content: '+';
  display: inline-block;
  font: 400 26px/20px 'DM Sans';
  height: 23px;
  margin-right: 8px;
  text-align: center;
  vertical-align: middle;
  width: 23px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.form__row__half {
  margin-bottom: 31px;
}

.form__label {
  color: #808080 !important;
  display: block;
  font-size: 18px !important;
  line-height: 24px !important;
  letter-spacing: 0.1px !important;
  margin-bottom: 8px;
  font-weight: 500;
}

.form__label__bold {
  color: #808080 !important;
  display: block;
  font-size: 18px !important;
  line-height: 24px !important;
  letter-spacing: 0.1px !important;
  margin-bottom: 8px;
  font-weight: 700;
}

.form__label.error {
  color: red;
}

.form__label--inline {
  float: left;
  line-height: 54px;
}

.form__action {
  margin-top: 32px;
  overflow: hidden;
}

.form__row--center {
  text-align: center;
}

.form__action .btn {
  border: 1px solid #cecece;
  font-size: 14px;
}
.form-in__row {
  display: flex;
  flex-wrap: wrap;
}

.form__col__seventy {
  flex: 0 0 70%;
  max-width: 70%;
}
.form__col__thirty {
  flex: 0 0 30%;
  max-width: 30%;
  padding-left: 20px;
}
.btn--inline {
  display: inline-block;
  float: none !important;
  margin-left: 24px;
  position: relative;
  top: 0;
}

.btn--standard {
  margin: 0 auto;
  -webkit-transition: background-color 300ms ease, color 300ms ease;
  transition: background-color 300ms ease, color 300ms ease;
  width: 210px;
  width: -webkit-calc(50% - 5px);
  width: calc(50% - 5px);
}

.btn--standard--center {
  margin: 0 auto !important;
}
.btn--action-inline {
  -webkit-transition: border 300ms ease;
  transition: border 300ms ease;
  width: 50%;
  width: -webkit-calc(50% - 5px);
  width: calc(50% - 5px);
}
.btn {
  background-color: transparent;
  -webkit-border-radius: 50px;
  border-radius: 50px;
  border: 2px solid #000;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #000;
  cursor: pointer;
  display: block;
  font: 400 16px/33px 'DM Sans';
  height: 37px;
  letter-spacing: 0.16px;
  outline: none;
  padding: 0 20px;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  -webkit-appearance: none !important;
  line-height: 1.5;
}
.btn--action-inline {
  -webkit-transition: border 300ms ease;
  transition: border 300ms ease;
  width: 50%;
  width: -webkit-calc(50% - 5px);
  width: calc(50% - 5px);
}
.btn--inline {
  display: inline-block !important;
  float: none !important;
  margin-left: 24px;
  position: relative;
  top: 0;
}
.form__row:before,
.form__row:after {
  content: '';
  display: table;
  clear: both;
}
.no-touch .btn:hover {
  text-decoration: none;
}
.btn.btn--standard:hover:enabled,
.btn.btn--standard:hover[enabled] {
  /* background-color: #e7ff35; */
  border: 2px solid #000;
}

.btn.btn-icon {
  background-size: 24px 24px;
  background-position: 12px center;
  background-repeat: no-repeat;
  padding-left: 40px;
  border: 1px solid #cecece;
  font-size: 14px;
  color: rgba(15, 18, 40, 0.9);
  transition: color 300ms ease-in-out, border 300ms ease-in-out;
  -webkit-transition: color 300ms ease-in-out, border 300ms ease-in-out;
}
.btn-slack {
  background-image: url(./assets/img/slack-logo.svg);
}
.btn-google {
  background-image: url(./assets/img/google.svg);
}
.btn-icon:hover {
  border-color: #000;
  color: #000;
}

.hide-on-phone {
  display: none;
}

.form__action:last-of-type {
  margin-top: -11px;
}

.form__text-input {
  background-color: transparent;
  border: 3px solid #E5E5E5;
  border-radius: 8px;
  height: 48px;
  font-size: 14px;
  line-height: 37px;
  letter-spacing: 0.14px;
  padding-left: 18px;
  outline: none;
  -webkit-transition: border-color 200ms ease;
  transition: border-color 200ms ease;
  text-align: left;
  width: 100%;
  -webkit-appearance: none;
}

.form__text-input.error {
  color: red;
  border-color: red;
}

@media screen and (min-width: 768px) {
  .management-panel {
    padding: 53px 40px 0;
  }

  .form__row {
    margin-bottom: 34px;
  }

  .form__row__half {
    float: left;
    margin-bottom: 0;
    width: -webkit-calc(50% - 12px);
    width: calc(50% - 12px);
  }

  .form__row__half:first-of-type {
    margin-right: 24px;
  }

  .form__text-input {
    font-size: 18px;
    letter-spacing: 0.18px;
    line-height: 42px;
  }

  .btn {
    font-size: 16px;
    height: 42px;
    letter-spacing: 0.16px;
    line-height: 2;
  }
  .hide-on-phone {
    display: inline;
  }
  .btn--standard {
    margin: 0;
    width: 210px;
    width: -webkit-calc(50% - 5px);
    width: calc(50% - 5px);
    line-height: 1;
  }
  .form__action:last-of-type {
    margin-top: -21px;
  }
}

@media screen and (min-width: 1024px) {
  .management-page {
    width: -webkit-calc(100% - 300px);
    width: calc(100% - 300px);
  }

  .management-panel {
    padding: 22px 40px 22px 150px;
  }

  .management-panel__inner {
    width: 778px;
  }
}
